<template>
  <div>
    <Header></Header>
    <div class="content">
      <swiper :options="swiperOption" ref="mySwiper" v-if="bannerPic.length > 0">
        <swiper-slide v-for="(item, index) in bannerPic" :key="index">
          <img :src="oss + item" class="img_qa" alt="" style="height: 100%" />
        </swiper-slide>
      </swiper>
      <!-- <img src="../assets/static/images/img_about2.png" class="img_qa" alt="" /> -->
      <div class="bread_nav flex-box">
        <div class="bread fs16 col3 plr30">
          当前位置：<router-link to="/">首页</router-link>><a href="javascript:;">关于我们</a>
        </div>
      </div>
      <div class="about_page">
        <div class="detail_box m1440 flex-box flex-col-start">
          <img :src="oss + img" class="img_abouttop" alt="" />

          <div class="flex-grow-1">
            <div class="fs36 fwb pb30">企业简介</div>
            <div class="fs20 col6 pb40">
              <div v-html="about_us.companyDesc"></div>
            </div>
            <!-- <div class="fs20 col6 pb40">环保桥的愿景：连接东西，绿色未来</div>
            <div class="fs20 col6 pb40">
              环保桥的使命：通过信息、资金、技术和碳信用的高效及低成本的交互，为个人、企业和政府应对气候变化提供最优的解决方案。
            </div>
            <div class="fs20 col6">环保桥的价值观：创新、专业、高效、诚信、赋能</div> -->
          </div>
        </div>
        <div class="service_box">
          <div class="m1440">
            <div class="fs36 tr pro_ser1">产品及服务</div>
            <div class="fs45 tr pro_ser2">PRODUCTS AND SERVICES</div>
            <div class="fs20 pb30 colf" style="width: 50%; line-height: 1.4">
              <div v-html="about_us.productService"></div>
            </div>
            <!-- <div class="fs20 pb30 colf">
              填埋场、煤矿瓦斯、养殖粪便、污水处理等甲烷回收利用碳减排项目开发；
            </div>
            <div class="fs20 pb30 colf">
              风电、光伏、生物质、氢能、地热等可再生能源碳减排项目开发；
            </div>
            <div class="fs20 pb30 colf">重点企事业单位温室气体碳排放核查咨询；</div>
            <div class="fs20 pb30 colf">企业、园区、地方政府碳达峰及碳中和解决方案；</div>
            <div class="fs20 pb30 colf">产品碳足迹核算咨询及碳中和综合服务；</div>
            <div class="fs20 pb30 colf">碳交易、碳资产管理、碳金融解决方案。</div> -->
          </div>
        </div>
        <div class="develop_box">
          <div class="m1440 relative">
            <div class="dev_text flex-box flex-col-start">
              <div class="box">
                <div class="fs36 fwb pb50">发展历程</div>
                <swiper :options="swiperOption" ref="mySwiper">
                  <swiper-slide v-for="(item, index) in about_us.milestones" :key="index">
                    <div class="fs20 col9 pb30">
                      <span class="fs36">{{ item.year }}</span> /{{ milestones_last.year }}
                    </div>
                    <!-- <div class="fs30 pb20">起步于国际碳交易市场</div> -->
                    <div class="fs20 col6 flex-grow-1 detail">
                      <div v-html="item.content"></div>
                    </div>
                  </swiper-slide>
                </swiper>
              </div>
              <img :src="oss + img1" class="img_abpage3" alt="" />
            </div>

            <div class="next_prev flex-box">
              <div class="next"></div>
              <div class="prev"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperOption: {
        // loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: true,
        },
        // 设置点击箭头
        navigation: {
          prevEl: ".next_prev .next",
          nextEl: ".next_prev .prev",
        },
      },
      oss: "",
      about_us: {},
      bannerPic: [],
      milestones_last: {},
      company_info: {},
      img:'',
      img1:''
    };
  },
  created() {
    let that = this;
    that.oss = that.global.uploadOss;
    that.get_about();
    that.get_img()
    that.get_companyInfo();
  },
  methods: {
    //获取
    get_about() {
      let that = this;
      let s_data = {
        sortType: "DESC",
        deviceType: "1",
      };
      that.$api.apiAboutUs(s_data).then((data_res) => {
        if (data_res.resCode == 0) {
          if (data_res.root) {
            let data = data_res.root[0];
            if (data.consultJson) {
              data.consultJson = JSON.parse(data.consultJson);
            }
            if (data.bannerPic) {
              that.bannerPic = data.bannerPic.split(",");
            }
            //翻转获取最后的年份
            if (data.milestones) {
              var s_data = JSON.parse(data.milestones);
              that.milestones_last = s_data[s_data.length - 1];
              data.milestones = s_data;
            }
            that.about_us = data;
          }
        } else {
          that.$message.error(data_res.resMsg);
        }
      });
    },

    get_companyInfo() {
      let that = this;
      that.$api.sysCompanyInfo({}).then((data_res) => {
        if (data_res.resCode == 0) {
          if (data_res.root) {
            let data = data_res.root[0];
            that.company_info = data;
          }
        } else {
          that.$message.error(data_res.resMsg);
        }
      });
    },


    get_img() {
      this.$api.apiIndexBanner({ contentKeys: 'ABOUT_US_PIC1,ABOUT_US_PIC2' }).then((data_res) => {
        //console.log(data_res)
        if (data_res.resCode == 0) {
          let root = data_res.root
          if (root[0] && root[0].pictureUrl) {
            var img = root[0].pictureUrl.split(',')
            this.img = img[0]
          }
          if (root[1] && root[1].pictureUrl) {
            var img1 = root[1].pictureUrl.split(',')
            this.img1 = img1[0]
          }
        } else {
          this.$message.error(data_res.resMsg);
        }
      });
    },

  },
};
</script>

<style scoped>
.swiper-container {
  height: 40vh;
  width: 100%;
}

.img_banner {
  object-fit: cover;
  -o-object-fit: cover;
}
.box{    
  display: flex;
    flex-direction: column;
    width: 50%;
    }
</style>
